<template>

  <div class="service_form_details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="8"
      >
        <b-row class="create-edit-form">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ evaluation.stringID }}
            </h1>
            <p class="log_info">
              Last edited by {{ evaluation.updatedBy ? evaluation.updatedBy.name : '' }} on {{ dateFormatWithTime(evaluation.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="4"
        cols="12"
      >
        <div class="demo-inline-spacing evaluation_sign">
          <div
            v-for="(signatory, index) of evaluation.signatories"
            :key="index"
          >
            <b-button
              v-if="signatory.signed === false"
              v-b-tooltip.hover
              :title="`Pending Sign by ${signatory.user ? signatory.user.name : ''}`"
              variant="flat-primary"
              class="btn-icon"
            >
              <feather-icon
                icon="FeatherIcon"
                size="18"
              />
            </b-button>
            <b-button
              v-else
              v-b-tooltip.hover
              :title="`Signed by ${signatory.user ? signatory.user.name : ''}`"
              variant="success"
              class="btn-icon"
            >
              <feather-icon
                icon="CheckIcon"
                size="18"
              />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 calllog-details evaluation_details serviceForm_details"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Vendor Evaluation Details</h3>
        </b-col>
        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            variant="flat-primary"
            :disabled="evaluation.status == 'approved' || !canViewThisAction('update-evaluation', 'Vendor')"
            @click="warningBeforeEdit()"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Company
            </b-th>
            <b-td>
              <span
                v-for="(entityVal, key) in evaluation.entity"
                :key="key"
              >
                <span v-if="key === 0">{{ entityVal.name }}</span>
                <span v-else> / {{ entityVal.name }}</span>
              </span>
            </b-td>
            <b-th width="230px">
              Created By
            </b-th>
            <b-td>{{ evaluation.createdBy ? evaluation.createdBy.name : '' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              No. of Vendors
            </b-th>
            <b-td>{{ evaluation.vendorCount }}</b-td>
            <b-th width="230px">
              Date
            </b-th>
            <b-td>{{ dateFormatWithTime(evaluation.createdAt) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Period of Evaluation
            </b-th>
            <b-td>{{ evaluation.period }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-card
      class="mb-3 p-2 without__bg"
      no-body
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left booking_content_wrapper mb-2"
          cols="12"
          md="12"
        >
          <div class="po__details_page">
            <!-- Table Container Card -->
            <b-card
              no-body
            >
              <div class="pur_con_header">
                <b-row
                  class="content-header"
                >
                  <!-- Content Left -->
                  <b-col
                    class="content-header-left"
                    md="6"
                    sm="6"
                  >
                    <b-button
                      variant="primary"
                      class="mr-1"
                      :disabled="evaluation.status == 'approved' || !canViewThisAction('update-evaluation', 'Vendor')"
                      @click="warningBeforeEdit()"
                    >
                      Edit
                    </b-button>
                    <!-- <b-button
                      variant="primary"
                      :disabled="evaluation.status != 'approved' || !canViewThisAction('download-ave', 'VendorEvaluation')"
                      @click="downloadAVE"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        size="14"
                      />
                    </b-button> -->
                  </b-col>
                  <b-col
                    class="content-header-right ml-auto text-right"
                    md="6"
                    sm="6"
                  >
                    <!-- <b-button
                      v-if="user._id == (evaluation.createdBy ? evaluation.createdBy._id : '') && evaluation.status != 'draft'"
                      variant="success"
                      disabled
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="mr-50"
                        size="18"
                      />
                      <span class="align-middle">
                        Submitted for Approval
                      </span>
                    </b-button> -->
                    <b-button
                      v-if="user._id == (evaluation.createdBy ? evaluation.createdBy._id : '') && evaluation.status == 'draft'"
                      variant="success"
                      :to="{ name: 'purchasing-vendors-evaluation-preview', params: { id: $route.params.id } }"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="mr-50"
                        size="18"
                      />
                      <span class="align-middle">
                        Submit For Approval
                      </span>
                    </b-button>
                    <div
                      v-for="(signatory, index) of evaluation.signatories"
                      :key="index"
                    >
                      <b-button
                        v-if="index != 0 && user._id == (signatory.user ? signatory.user._id : '') && evaluation.signatories[index - 1].signed == true && signatory.signed == false"
                        variant="success"
                        :to="{ name: 'purchasing-vendors-evaluation-preview', params: { id: $route.params.id }, query: { type: index, user: signatory._id } }"
                      >
                        <feather-icon
                          icon="CheckIcon"
                          class="mr-50"
                          size="18"
                        />
                        <span class="align-middle">
                          Verify AVE
                        </span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="pur_con_body quote__table">
                <div class="position-relative has_padding quote__table table-responsive evaluation__details_table">
                  <table
                    class="table b-table b-table-caption-top evaluation__table"
                  >
                    <thead style="display: table-header-group;">
                      <tr>
                        <th>No</th>
                        <th style="width:227px; min-width:227px;">
                          vendor
                        </th>
                        <th
                          class="th__row"
                          style="width:633px; min-width:633px;"
                        >
                          <b-row>
                            <b-col
                              cols="12"
                              md="6"
                              style="width:377px; min-width:377px; max-width:377px"
                            >
                              <span>Supplied Item Description</span>
                            </b-col>
                            <b-col
                              cols="12"
                              md="2"
                              style="width:72px; min-width:72px; max-width:72px"
                            >
                              <span>Unit</span>
                            </b-col>
                            <b-col
                              cols="12"
                              md="2"
                              style="width:95px; min-width:95px; max-width:95px"
                            >
                              <span>Unit Price<br>(S$)</span>
                            </b-col>
                            <b-col
                              cols="12"
                              md="2"
                              style="width:76px; min-width:76px; max-width:76px"
                            >
                              <span>Adj Unit <br>price</span>
                            </b-col>
                          </b-row>
                        </th>
                        <th>credit term<br>(days)</th>
                        <th>late delivery<br>(days)</th>
                        <th>late delivery<br>(times)</th>
                        <th>no. of<br>ncf</th>
                        <th>ADJ Unit<br>price</th>
                        <th>credit<br>term</th>
                        <th>delivery/<br>services</th>
                        <th>Quality</th>
                        <th>Total<br>points</th>
                        <th>ranking</th>
                        <th>justification / remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in evaluationItems"
                        :key="index"
                        style="page-break-inside: avoid; page-break-after: auto;"
                      >
                        <td style="page-break-inside: avoid; page-break-after: auto;">
                          {{ ++index }}
                        </td>
                        <td
                          style="page-break-inside: avoid; page-break-after: auto;"
                        >
                          <span class="text-bold-black">{{ item.vendorName }}</span>
                          <br>
                          <b-button
                            v-if="canViewThisAction('show', 'PurchaseRequest')"
                            variant="link"
                            class="align-middle p-0 text-left"
                            :to="{ name: 'purchasing-purchase-request-show', params: { id: item.purchaseRequest } }"
                          >
                            {{ item.caseID }}
                          </b-button>
                          <b-button
                            v-else
                            variant="none"
                            class="align-middle p-0 text-left not-button"
                          >
                            {{ item.caseID }}
                          </b-button>
                        </td>
                        <b-td>
                          <b-row
                            v-for="(pitem, itemIndex) in item.items"
                            :key="itemIndex"
                            class="mb-50"
                            style="width:633px; min-width:633px; max-width:633px"
                          >
                            <b-col
                              cols="12"
                              md="6"
                              style="width:377px; min-width:377px; max-width:377px"
                            >
                              <span class="">{{ pitem }}</span>
                            </b-col>
                            <b-col
                              cols="12"
                              md="2"
                              style="width:72px; min-width:72px; max-width:72px"
                            >
                              <span class="">{{ item.units[itemIndex] }}</span>
                            </b-col>
                            <b-col
                              cols="12"
                              md="2"
                              style="width:95px; min-width:95px; max-width:95px"
                            >
                              <span class="">{{ numberFormat(item.unitCosts[itemIndex]) }}</span>
                            </b-col>
                            <b-col
                              cols="12"
                              md="2"
                              style="width:76px; min-width:76px; max-width:76px"
                            >
                              <span class="">{{ item.adjustedUnitPrices[itemIndex] }}</span>
                            </b-col>
                          </b-row>
                        </b-td>
                        <b-td>
                          {{ item.creditTermDays }}
                        </b-td>
                        <b-td>
                          {{ item.lateDeliveryDays }}
                        </b-td>
                        <b-td>
                          {{ item.lateDeliveryTimes }}
                        </b-td>
                        <b-td>
                          {{ item.numberOfNCF }}
                        </b-td>
                        <b-td>
                          {{ item.numberOfAdjustedUnitPrice }}
                        </b-td>
                        <b-td>
                          {{ item.numberOfCreditTerm }}
                        </b-td>
                        <b-td>
                          {{ item.numberOfDelivery }}
                        </b-td>
                        <b-td>
                          {{ item.quantity }}
                        </b-td>
                        <b-td>
                          {{ item.totalPoint }}
                        </b-td>
                        <b-td>
                          {{ item.ranking }}
                        </b-td>
                        <b-td>
                          {{ item.remarks }}
                        </b-td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="signatories_block pt-2">
                <b-row
                  class="content-header"
                >

                  <!-- Content Left -->
                  <b-col
                    v-for="(signatory, index) of evaluation.signatories"
                    :key="index"
                    cols="3"
                    md="3"
                    class="mt-md-0 mt-3"
                    order="2"
                    order-md="1"
                  >
                    <b-card-text class="mb-0">
                      <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
                      <div class="sign-box">
                        <div v-if="signatory.user">
                          <b-avatar
                            v-if="signatory.user.image"
                            :src="signatory.user.image"
                          />
                          <b-avatar
                            v-else
                            :text="resolveAcronymName(signatory.user.name)"
                          />
                        </div>
                        <div v-if="signatory.signed == true">
                          <!-- <div v-if="signatory.user && signatory.user.signature">
                            <b-img
                              class="mb-1 mb-sm-0 user__avatar"
                              height="50"
                              :src="signatory.user.signature"
                            />
                          </div> -->
                          <div v-if="signatory.user && signatory.user.name">
                            <span>{{ signatory.user.name }}</span>
                          </div>
                        </div>
                        <div v-else>
                          <p>Pending Signature</p>
                        </div>
                      </div>
                      <p class="ml-75 semi-bold">
                        {{ signatory.user ? signatory.user.name : '' }}
                      </p>
                      <p class="ml-75">
                        {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
                      </p>
                      <p class="ml-75">
                        {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
                      </p>
                    </b-card-text>
                  </b-col>
                </b-row>
              </div>
            </b-card>

            <!-- <pdf-view
              ref="pdfGenerateComponentPO"
              :evaluation="evaluation"
              :evaluation-items="evaluationItems"
              :pdf-total-page="pdfTotalPage"
              :chunks-of-item-requests="chunksOfItemRequests"
              :chunk-item-requests="chunkItemRequests"
              :gap-height="gapHeight"
            /> -->
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BCardText, BAvatar, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import store from '@/store/index'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCardText,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      canViewThisAction,
      evaluation: {},
      user: store.state.auth.userData,
      evaluationSignatories: [],
      evaluationItems: [],
      required,
      lqas: [
        '1. Prepared by',
        '2. Requested by',
        '3. Verified by',
        '4. Recommended by',
        '5. Agreed by',
        '6. Certified by',
        '7. Confirmed by',
        '8. Supported by',
        '9. Validated by',
        '10. Approved by',
      ],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  mounted() {
    this.$http.get(`purchase/vendor-evaluations/${this.$route.params.id}/show`)
      .then(response => {
        this.evaluation = response.data.evaluation || {}
        this.evaluationItems = response.data.evaluationItems || {}
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    warningBeforeEdit() {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Editing the form now will <strong>reset the approval process</strong> and require all signatories to confirm and sign once again.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Edit this AVE',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-vendors-evaluation-update', params: { id: this.$route.params.id } })
          }
        })
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
  },
}
</script>

<style scoped>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
